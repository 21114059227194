
import _ from "underscore";
import { Component, Vue } from "vue-property-decorator";
import * as CountryService from "@/api/helpers/Country";
import {
  findCampaignDetails,
  patchCampaign,
  findCampaignPhones,
  findCampaignShipments,
  Campaign as SMSCampaign,
} from "@/api/tharbad";
import { countryCode2FullNameFilter } from "@/filters";
import TimeLineShipment from "@/components/sms/TimeLineShipment.vue";
import AddShipmentDrawer from "@/components/sms/AddShipmentDrawer.vue";
import { CatalogItem } from "@/models/Catalog";
import Notify from "@/utils/notifications";

@Component({
  components: { TimeLineShipment, AddShipmentDrawer },
  filters: { countryCode2FullNameFilter },
})
export default class CampaignTemplatesView extends Vue {
  updateCampaign = false;
  updateLoading = false;
  fullscreenLoading = true;
  campaignName = "";
  campaign: SMSCampaign | null = null;
  countries: CatalogItem<string>[] = [];
  tooltipSpend = 0;
  selected = ["2"];
  shipmentsPendings = [];
  shipmentsPassed = [];
  showDrawer = false;

  async getCampaign() {
    try {
      this.fullscreenLoading = true;
      this.campaign = await findCampaignDetails(this.$route.params.id);
      const currentSpend = JSON.parse(process.env.VUE_APP_THARBAD_SPEND);
      this.tooltipSpend =
        this.campaign["total-credit-spend"] *
        currentSpend[this.campaign["country-code"]];
      this.tooltipSpend = this.tooltipSpend.toFixed(2);
      this.fullscreenLoading = false;
    } catch (error) {
      this.$router.back();
      Notify.error(error);
    }
  }

  async getShipments() {
    this.fullscreenLoading = true;
    this.shipments = await findCampaignShipments(this.$route.params.id);
    this.shipmentsPendings = _.filter(this.shipments, s => {
      return (
        !s.status ||
        s.status.name == "Pendiente" ||
        s.status.name == "Cancelado"
      );
    });
    this.shipmentsPassed = _.filter(this.shipments, s => {
      return (
        s.status && s.status.name != "Pendiente" && s.status.name != "Cancelado"
      );
    });
    if (this.shipmentsPassed.length == 0) this.selected = ["1"];
    this.fullscreenLoading = false;
  }

  async patchCampaign() {
    this.updateLoading = true;
    await patchCampaign(this.campaign.id, { name: this.campaign.name });
    Notify.successful("Se modificó el nombre de las campaña.");
    this.updateLoading = false;
    this.updateCampaign = false;
  }

  saveName(name: string) {
    this.campaignName = name;
  }

  async downloadPhones() {
    const phones = await findCampaignPhones(this.$route.params.id);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([phones]));
    link.setAttribute("download", `${this.campaign.name}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  newShipment() {
    this.showDrawer = true;
  }

  closeDrawer() {
    this.showDrawer = false;
  }

  created() {
    const index = "5.1";
    const title = "Detalles de campaña";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "SMS",
        to: {
          name: "sms-campaign",
        },
      },
      {
        text: "Campañas",
        to: {
          name: "sms-campaign",
        },
      },
      {
        text: "Detalles",
      },
    ]);
    Promise.all([
      CountryService.findCountryCodes().then(data => {
        this.countries = data.map(x => {
          return { id: x.countryCode, description: x.description };
        });
      }),
      this.getCampaign(),
      this.getShipments(),
    ]);
  }
}
